import { Subscription } from "@etechinc/smartview-core";
import { poll } from "../utils/reduxUtils";
import { useGetSubscriptionsQuery } from "../slices/subscriptionApi";

export const useSubscription = (jobNumber?: string): Subscription | undefined => {
  const { subscription } = useGetSubscriptionsQuery(undefined, {
    selectFromResult: ({ data: subscriptions }) => ({
      subscription: subscriptions?.find((subscription) => subscription.jobNumber === jobNumber),
    }),
    ...poll(300),
    skip: !jobNumber,
  });

  return subscription;
};
