import React from "react";
import { FacilityTimeZoneTableCell } from "./FacilityTimeZoneTableCell";
import { Link } from "../../components/Link";
import { LogisticsSystemStatusTableCell } from "./LogisticsSystemStatusTableCell";
import { LogisticsSystemType } from "@etechinc/smartview-core";
import { SubscriptionTableCell } from "./SubscriptionTableCell";
import { Table } from "@radix-ui/themes";
import { linkToJob } from "../../utils/linkTo";
import { useLogisticsSystemStatuses } from "../../hooks/useLogisticsSystemStatuses";

export const LogisticsSystemStatusTable = () => {
  const statuses = useLogisticsSystemStatuses();

  return (
    <>
      <Table.Root size="1" mb="2">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Job</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Hub</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Monorail</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>SLX</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Time Zone</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Subscription</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {statuses &&
            Object.entries(statuses)
              .sort()
              .map(([jobNumber, statusList]) => (
                <Table.Row>
                  <Table.RowHeaderCell>
                    <Link to={linkToJob(jobNumber)}>{jobNumber}</Link>
                  </Table.RowHeaderCell>
                  <LogisticsSystemStatusTableCell
                    status={statusList.find((status) => status.type === LogisticsSystemType.HUB)}
                  />
                  <LogisticsSystemStatusTableCell
                    status={statusList.find((status) => status.type === LogisticsSystemType.MONORAIL)}
                  />
                  <LogisticsSystemStatusTableCell
                    status={statusList.find((status) => status.type === LogisticsSystemType.SLX)}
                  />
                  <FacilityTimeZoneTableCell jobNumber={jobNumber} />
                  <SubscriptionTableCell jobNumber={jobNumber} />
                </Table.Row>
              ))}
        </Table.Body>
      </Table.Root>
    </>
  );
};
