import React from "react";
import { Flex, Table } from "@radix-ui/themes";
import { useFacility } from "../../hooks/useFacility";

interface Props {
  jobNumber: string;
}

export const FacilityTimeZoneTableCell = ({ jobNumber }: Props) => {
  const facility = useFacility(jobNumber);

  return (
    <>
      <Table.Cell>
        <Flex align="center" gap="1">
          {facility?.timeZone ?? "--"}
        </Flex>
      </Table.Cell>
    </>
  );
};
