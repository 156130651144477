import { Facility } from "@etechinc/smartview-core";
import { poll } from "../utils/reduxUtils";
import { useGetFacilitiesQuery } from "../slices/facilityApi";

/**
 * Get facility by job number.
 * Usage of `useGetFacilitiesQuery` only return facilities user has access to,
 * based upon their organization configuration.
 */
export const useFacility = (jobNumber?: string): Facility | undefined => {
  const { facility } = useGetFacilitiesQuery(undefined, {
    selectFromResult: ({ data: facilities }) => ({
      facility: facilities?.find((facility) => facility.jobNumber === jobNumber),
    }),
    ...poll(900),
    skip: !jobNumber,
  });

  return facility;
};
