import React from "react";
import dayjs from "dayjs";
import { Flex, Table } from "@radix-ui/themes";
import { useSubscription } from "../../hooks/useSubscription";

interface Props {
  jobNumber: string;
}

export const SubscriptionTableCell = ({ jobNumber }: Props) => {
  const subscription = useSubscription(jobNumber);

  return (
    <>
      <Table.Cell>
        <Flex align="center" gap="1">
          {subscription?.expiration ? dayjs(subscription.expiration).format("MMMM D, YYYY") : "--"}
        </Flex>
      </Table.Cell>
    </>
  );
};
