import { Subscription } from "@etechinc/smartview-core";
import { cloudApi } from "./cloudApi";

export const subscriptionApi = cloudApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query<Subscription[], void>({
      query: () => `/subscription`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetSubscriptionsQuery } = subscriptionApi;
